import { useEffect, useRef, useState } from 'react';
import { Bold7Text, SnapBadge } from '@snapmint/ui-components';
import { useUrlParamParser } from '../../page/util/url-param-parse.hook';
import { captureEvent } from '../../events/event.helper';
import { EventName } from '../../events/event-name.enum';
import { useApp } from '../../app.hook';
import { setLocalStorageKey } from '../../utils/local-stoarge.helper';

interface CashbackRedeemProps {
    isCashbackDetailPage?: boolean
}

export const CashbackRedeem = (props: CashbackRedeemProps) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const { cashbackConfig, isCashBackUsed } = useApp();
    const transactionParamModel = useUrlParamParser();
    const { applicableCashBack = 0, cashbackAmtApplied = 0, availableCashBack = 0, cashbackPercent, maxCashback } = cashbackConfig;
    const { merchantName, orderValue, checkoutId, merchantId, userId } = transactionParamModel;
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
            setTooltipVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getInfoButton = () => (
        <div className="relative">
            <img
                src="/assets/images/info.svg"
                onMouseEnter={() => {}}
                onClick={() => {
                    setTooltipVisible((pre) => !pre);
                }}
                alt="i"
                className="w-[10px] h-[10px] cursor-pointer"
            />
            {tooltipVisible && (
                <div className='absolute top-12 -left-[107px] w-56 bg-[#FFFACF] text-headGreen text-[12px] px-[12px] py-[8px] rounded-[6px] z-[999]'>
                    <div className="relative">
                        <div
                            className={`w-0 h-0 absolute left-1/2 -translate-x-1/2 -top-[18px] border-l-[10px] border-l-transparent border-b-[15px] border-b-[#FFFACF] border-r-[10px] border-r-transparent`}
                        />
                        <p className="font-interBold">
                            Available Cashback : ₹{availableCashBack} <br />
                            Usable Cashback : ₹{cashbackAmtApplied} <br />
                        </p>
                        <span className="font-robotoRegular text-[#657173] text-[11px]">
                            *Use Cashback to discount {cashbackPercent}% of order value up to ₹{applicableCashBack}
                        </span>
                        <img
                            src="/assets/images/cross_icon.svg"
                            alt="cross icons"
                            onClick={() => {
                                setTooltipVisible((pre) => !pre);
                            }}
                            className="absolute top-0 right-0 mt-1 mr-1 h-[14px] cursor-pointer w-4"
                        />
                    </div>
                </div>
            )}
        </div>
    );

    if (!isCashBackUsed) {
        return null;
    }

    return (
        <div className="flex justify-between w-full pb-[8px] mb-[2px] border-b border-b-[#DEDEDE]">
            <div className="relative flex items-center">
                <>
                    <span className="font-robotoBold text-headGreen text-[11px] pr-[2px]">Cashback Used</span>
                    <div ref={tooltipRef}>
                        <SnapBadge className={`rounded-full bg-emiTab px-2 py-[1.2px]`}>
                            <div className="flex items-center">
                                <Bold7Text text={`₹${cashbackAmtApplied}`} className="!text-[9px] text-headGreen" />
                                {getInfoButton()}
                            </div>
                        </SnapBadge>
                    </div>
                </>
            </div>
            <div className="font-robotoRegular text-[#657173] text-[10px]">
                {cashbackPercent}% upto ₹{maxCashback} allowed
            </div>
        </div>
    );
};
